import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal, Input, Table, Checkbox } from 'antd';
import { func, arrayOf, string, bool } from 'prop-types';
import { selectHospitalsInAlphabeticalOrder } from '@redux/hospitals/reducers';
import { selectOrganisation } from '@redux/organisations/reducers';
import { fetchHospitals } from '@redux/hospitals/actions';
import { useSearch } from '@hooks';
import './style.less';

const filterFunc = (value, comparator) => comparator(value.name);

function SharingSettings({ selectedOrgIds, setSelectedOrgIds }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [, hospitals] = useSelector(selectHospitalsInAlphabeticalOrder);
  const [, organisation] = useSelector(selectOrganisation());

  useEffect(() => {
    dispatch(fetchHospitals());
  }, [dispatch]);

  const [searchResults, searchProps] = useSearch(hospitals, filterFunc);

  const columns = [
    {
      title: t('common:name'),
      dataIndex: 'name',
      key: 'name',
    },
  ];

  return (
    <div className="sharing-settings">
      <Input.Search placeholder={t('common:search')} {...searchProps} />
      <Table
        columns={columns}
        dataSource={searchResults.filter(
          hospital => hospital.slug !== organisation?.organisation_slug,
        )}
        pagination={{ position: 'bottom' }}
        rowKey="slug"
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: selectedOrgIds,
          onChange: selectedRowKeys => {
            setSelectedOrgIds(selectedRowKeys);
          },
        }}
      />
    </div>
  );
}

SharingSettings.propTypes = {
  selectedOrgIds: arrayOf(string),
  selectedRowKeys: arrayOf(string).isRequired,
  setSelectedRowKeys: func.isRequired,
};

SharingSettings.defaultProps = {
  selectedOrgIds: [],
};

export { SharingSettings };

function SharingSettingsModal({
  onOk,
  onCancel,
  selectedOrgIds,
  setSelectedOrgIds,
  isCurrentlyShared,
}) {
  const { t } = useTranslation();
  const [share, setShare] = useState(isCurrentlyShared);

  return (
    <Modal
      className="sharing-settings-modal"
      title={t('pathways:ProcedurePathways.snapshotSharingSettings.title')}
      visible
      onCancel={onCancel}
      onOk={() => onOk(share)}
    >
      <Checkbox onChange={e => setShare(e.target.checked)} checked={share}>
        {t('pathways:ProcedurePathways.createSnapshot.makeShared')}
      </Checkbox>
      {share && (
        <>
          <div className="sharing-settings-gap" />
          <SharingSettings selectedOrgIds={selectedOrgIds} setSelectedOrgIds={setSelectedOrgIds} />
        </>
      )}
    </Modal>
  );
}

SharingSettingsModal.propTypes = {
  onOk: func.isRequired,
  onCancel: func.isRequired,
  selectedOrgIds: arrayOf(string).isRequired,
  setSelectedOrgIds: func.isRequired,
  isCurrentlyShared: bool.isRequired,
};

export default SharingSettingsModal;
