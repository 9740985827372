import React from 'react';
import { createLanguageFilters } from '@utils/supportedLanguages';
import { trimTextToApproximateLength } from '@utils';
import LanguageIcon from '@components/LanguageIcon';
import PublishedStatusColumn from './PublishedStatusColumn';

// TODO: to be reviewed. Most documents don't have tags in their metadata.
export default (t, findLanguage, findSource, documentTranslationKey) => [
  {
    className: 'language-column',
    dataIndex: 'metadata',
    filters: createLanguageFilters(t),
    key: 'language',
    title: t('cards:DocumentList.columns.language.title'),
    onFilter: (value, record) => {
      return (findLanguage(record) || '').toLowerCase() === value;
    },
    render: metadata => <LanguageIcon language={findLanguage({ metadata })} />,
    width: 125,
  },
  {
    title: t(`cards:DocumentList.columns.${documentTranslationKey}.title`),
    dataIndex: 'name',
    key: 'title',
    ellipsis: true,
    sorter: (a, b) => a.name.localeCompare(b.title),
  },
  {
    title: t('cards:DocumentList.columns.description'),
    dataIndex: 'description',
    key: 'description',
    render: description => trimTextToApproximateLength(description),
    sorter: (a, b) => a.description > b.description,
  },
  {
    title: t('cards:DocumentList.columns.audience.title'),
    dataIndex: 'audience_type',
    key: 'audience_type',
    width: 200,
    render: at => t(`common:audienceType:${at}`),
    filters: [
      {
        text: t('common:audienceType.app-user'),
        value: 'app-user',
      },
      {
        text: t('common:audienceType.clinician'),
        value: 'clinician',
      },
    ],
    onFilter: (value, record) => record.audience_type === value,
  },
  {
    className: 'published-column',
    key: 'published',
    title: t('cards:DocumentList.columns.published.title'),
    render: record => <PublishedStatusColumn documentId={record.id} />,
    width: 155,
    filters: [
      {
        text: t('cards:DocumentList.columns.published.published'),
        value: true,
      },
      {
        text: t('cards:DocumentList.columns.published.unpublished'),
        value: false,
      },
    ],
    onFilter: (value, record) => record.published === value,
  },
];
