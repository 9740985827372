import {
  CREATE_PATHWAY,
  CREATE_PATHWAY_FAILURE,
  CREATE_PATHWAY_SUCCESS,
  DELETE_PATHWAY,
  DELETE_PATHWAY_FAILURE,
  DELETE_PATHWAY_SUCCESS,
  DELETE_PATHWAYS,
  DELETE_PATHWAYS_FAILURE,
  DELETE_PATHWAYS_SUCCESS,
  DUPLICATE_PATHWAY,
  DUPLICATE_PATHWAY_SUCCESS,
  DUPLICATE_PATHWAYS,
  DUPLICATE_PATHWAYS_SUCCESS,
  EDIT_PATHWAY,
  EDIT_PATHWAY_FAILED,
  EDIT_PATHWAY_SUCCESS,
  FETCH_PATHWAYS,
  FETCH_PATHWAYS_ERROR,
  FETCHING_PATHWAYS,
  LOAD_PATHWAYS,
  PUBLISH_PATHWAYS,
  PUBLISH_PATHWAYS_SUCCESS,
  UNPUBLISH_PATHWAYS,
  UNPUBLISH_PATHWAYS_SUCCESS,
  FETCH_ENGAGEMENT_CHECKS,
  FETCH_ENGAGEMENT_CHECKS_SUCCESS,
  FETCH_ENGAGEMENT_CHECKS_FAILED,
  ADD_ENGAGEMENT_CHECK,
  ADD_ENGAGEMENT_CHECK_SUCCESS,
  ADD_ENGAGEMENT_CHECK_FAILED,
  EDIT_ENGAGEMENT_CHECK,
  EDIT_ENGAGEMENT_CHECK_SUCCESS,
  EDIT_ENGAGEMENT_CHECK_FAILED,
  DELETE_ENGAGEMENT_CHECK,
  DELETE_ENGAGEMENT_CHECK_SUCCESS,
  DELETE_ENGAGEMENT_CHECK_FAILED,
  FETCH_PATHWAY_SNAPSHOTS,
  FETCH_PATHWAY_SNAPSHOTS_SUCCESS,
  FETCH_PATHWAY_SNAPSHOTS_FAILED,
  CREATE_PATHWAY_SNAPSHOT,
  CREATE_PATHWAY_SNAPSHOT_SUCCESS,
  CREATE_PATHWAY_SNAPSHOT_FAILED,
  SHARE_PATHWAY_SNAPSHOT,
  SHARE_PATHWAY_SNAPSHOT_SUCCESS,
  SHARE_PATHWAY_SNAPSHOT_FAILED,
  UNSHARE_PATHWAY_SNAPSHOT,
  UNSHARE_PATHWAY_SNAPSHOT_SUCCESS,
  UNSHARE_PATHWAY_SNAPSHOT_FAILED,
  IPathwayData,
  IPathway,
  IPathwaySnapshot,
  IPathwaySnapshotData,
} from './types';

import { IReduxAction } from '@redux/types';

// TODO - requires pathway type from pathways-client
export interface ICreatePathway
  extends IReduxAction<typeof CREATE_PATHWAY, { pathway: IPathwayData }> {}
export const createPathway = (pathway: IPathwayData) => ({
  type: CREATE_PATHWAY,
  payload: { pathway },
});

export interface ICreatePathwayFailure extends IReduxAction<typeof CREATE_PATHWAY_FAILURE> {}
export const createPathwayFailure = () => ({
  type: CREATE_PATHWAY_FAILURE,
  payload: {},
});

// TODO - requires pathway type from pathways-client
export interface ICreatePathwaySuccess
  extends IReduxAction<typeof CREATE_PATHWAY_SUCCESS, { pathway: IPathway }> {}
export const createPathwaySuccess = (pathway: IPathway) => ({
  type: CREATE_PATHWAY_SUCCESS,
  payload: { pathway },
});

export interface IDuplicatePathway
  extends IReduxAction<typeof DUPLICATE_PATHWAY, { pathwayId: number }> {}
export const duplicatePathway = (pathwayId: number) => ({
  type: DUPLICATE_PATHWAY,
  payload: { pathwayId },
});

export interface IDuplicatePathwaySuccess
  extends IReduxAction<typeof DUPLICATE_PATHWAY_SUCCESS, { duplicatedPathway: IPathway }> {}
export const duplicatePathwaySuccess = (duplicatedPathway: IPathway) => ({
  type: DUPLICATE_PATHWAY_SUCCESS,
  payload: { duplicatedPathway },
});

export interface IDuplicatePathways extends IReduxAction<typeof DUPLICATE_PATHWAYS> {}
export const duplicatePathways = (pathwayIds: Array<number>) => ({
  type: DUPLICATE_PATHWAYS,
  payload: { pathwayIds },
});

export interface IDuplicatePathwaysSuccess
  extends IReduxAction<typeof DUPLICATE_PATHWAYS_SUCCESS> {}
export const duplicatePathwaysSuccess = () => ({
  type: DUPLICATE_PATHWAYS_SUCCESS,
  payload: {},
});

export interface IDeletePathway
  extends IReduxAction<typeof DELETE_PATHWAY, { pathwayId: number }> {}
export const deletePathway = (pathwayId: number) => ({
  type: DELETE_PATHWAY,
  payload: { pathwayId },
});

export interface IDeletePathwayFailure
  extends IReduxAction<typeof DELETE_PATHWAY_FAILURE, { pathwayId: number }> {}
export const deletePathwayFailure = () => ({
  type: DELETE_PATHWAY_FAILURE,
  payload: {},
});

export interface IDeletePathwaySuccess
  extends IReduxAction<typeof DELETE_PATHWAY_SUCCESS, { pathwayId: number }> {}
export const deletePathwaySuccess = (pathwayId: number) => ({
  type: DELETE_PATHWAY_SUCCESS,
  payload: { pathwayId },
});

export interface IDeletePathways
  extends IReduxAction<typeof DELETE_PATHWAYS, { pathwayIds: Array<number> }> {}
export const deletePathways = (pathwayIds: Array<number>) => ({
  type: DELETE_PATHWAYS,
  payload: { pathwayIds },
});

export interface IDeletePathwaysFailure extends IReduxAction<typeof DELETE_PATHWAYS_FAILURE> {}
export const deletePathwaysFailure = () => ({
  type: DELETE_PATHWAYS_FAILURE,
  payload: {},
});

export interface IDeletePathwaysSuccess
  extends IReduxAction<typeof DELETE_PATHWAYS_SUCCESS, { pathwayIds: Array<number> }> {}
export const deletePathwaysSuccess = (pathwayIds: Array<number>) => ({
  type: DELETE_PATHWAYS_SUCCESS,
  payload: { pathwayIds },
});

// TODO
export interface IEditPathway
  extends IReduxAction<typeof EDIT_PATHWAY, { pathwayId: number; editedPathway: IPathway }> {}
export const editPathway = (pathwayId: number, editedPathway: any) => ({
  type: EDIT_PATHWAY,
  payload: { pathwayId, editedPathway },
});

// TODO - requires pathway type from pathways-client
export interface IEditPathwaySuccess
  extends IReduxAction<typeof EDIT_PATHWAY_SUCCESS, { pathway: IPathway }> {}
export const editPathwaySuccess = (pathway: IPathway) => ({
  type: EDIT_PATHWAY_SUCCESS,
  payload: { pathway },
});

export interface IEditPathwayFailed extends IReduxAction<typeof EDIT_PATHWAY_FAILED> {}
export const editPathwayFailed = () => ({
  type: EDIT_PATHWAY_FAILED,
  payload: {},
});

export interface IFetchPathways
  extends IReduxAction<
    typeof FETCH_PATHWAYS,
    { fetchSnapshots?: boolean; parentPathwayId?: number }
  > {}
export const fetchPathways = (
  fetchSnapshots?: boolean,
  parentPathwayId?: number,
): IFetchPathways => ({
  type: FETCH_PATHWAYS,
  payload: { fetchSnapshots, parentPathwayId },
});

export interface IFetchingPathways extends IReduxAction<typeof FETCHING_PATHWAYS> {}
export const fetchingPathways = (): IFetchingPathways => ({
  type: FETCHING_PATHWAYS,
  payload: {},
});

export interface IFetchPathwaysError
  extends IReduxAction<typeof FETCH_PATHWAYS_ERROR, { error: string }> {}
export const fetchPathwaysError = (error: string): IFetchPathwaysError => ({
  type: FETCH_PATHWAYS_ERROR,
  payload: { error },
});

export interface ILoadPathways
  extends IReduxAction<typeof LOAD_PATHWAYS, { pathways: IPathway[] }> {}
export const loadPathways = (pathways: IPathway[]): ILoadPathways => ({
  type: LOAD_PATHWAYS,
  payload: { pathways },
});

export interface IPublishPathways
  extends IReduxAction<typeof PUBLISH_PATHWAYS, { pathwayIds: Array<number> }> {}
export const publishPathways = (pathwayIds: Array<number>): IPublishPathways => ({
  type: PUBLISH_PATHWAYS,
  payload: { pathwayIds },
});

export interface IPublishPathwaysSuccess
  extends IReduxAction<typeof PUBLISH_PATHWAYS_SUCCESS, { pathwayIds: Array<number> }> {}
export const publishPathwaysSuccess = (pathwayIds: Array<number>): IPublishPathwaysSuccess => ({
  type: PUBLISH_PATHWAYS_SUCCESS,
  payload: { pathwayIds },
});

export interface IUnpublishPathways
  extends IReduxAction<typeof UNPUBLISH_PATHWAYS, { pathwayIds: Array<number> }> {}
export const unpublishPathways = (pathwayIds: Array<number>): IUnpublishPathways => ({
  type: UNPUBLISH_PATHWAYS,
  payload: { pathwayIds },
});

export interface IUnpublishPathwaysSuccess
  extends IReduxAction<typeof UNPUBLISH_PATHWAYS_SUCCESS, { pathwayIds: Array<number> }> {}
export const unpublishPathwaysSuccess = (pathwayIds: Array<number>) => ({
  type: UNPUBLISH_PATHWAYS_SUCCESS,
  payload: { pathwayIds },
});

export interface IAddEngagementCheck
  extends IReduxAction<typeof ADD_ENGAGEMENT_CHECK, { pathwayId: number; engagementCheck: any }> {}
export const addEngagementCheck = (
  pathwayId: number,
  engagementCheck: any,
): IAddEngagementCheck => ({
  type: ADD_ENGAGEMENT_CHECK,
  payload: { pathwayId, engagementCheck },
});

export interface IAddEngagementCheckSuccess
  extends IReduxAction<
    typeof ADD_ENGAGEMENT_CHECK_SUCCESS,
    { pathwayId: number; engagementCheck: any }
  > {}
export const addEngagementCheckSuccess = (
  pathwayId: number,
  engagementCheck: any,
): IAddEngagementCheckSuccess => ({
  type: ADD_ENGAGEMENT_CHECK_SUCCESS,
  payload: { pathwayId, engagementCheck },
});

export interface IAddEngagementCheckFailed
  extends IReduxAction<typeof ADD_ENGAGEMENT_CHECK_FAILED, { pathwayId: number }> {}
export const addEngagementCheckFailed = (pathwayId: number): IAddEngagementCheckFailed => ({
  type: ADD_ENGAGEMENT_CHECK_FAILED,
  payload: { pathwayId },
});

export interface IEditEngagementCheck
  extends IReduxAction<typeof EDIT_ENGAGEMENT_CHECK, { pathwayId: number; engagementCheck: any }> {}
export const editEngagementCheck = (
  pathwayId: number,
  engagementCheck: any,
): IEditEngagementCheck => ({
  type: EDIT_ENGAGEMENT_CHECK,
  payload: { pathwayId, engagementCheck },
});

export interface IEditEngagementCheckSuccess
  extends IReduxAction<
    typeof EDIT_ENGAGEMENT_CHECK_SUCCESS,
    { pathwayId: number; engagementCheck: any }
  > {}
export const editEngagementCheckSuccess = (
  pathwayId: number,
  engagementCheck: any,
): IEditEngagementCheckSuccess => ({
  type: EDIT_ENGAGEMENT_CHECK_SUCCESS,
  payload: { pathwayId, engagementCheck },
});

export interface IEditEngagementCheckFailed
  extends IReduxAction<typeof EDIT_ENGAGEMENT_CHECK_FAILED, { pathwayId: number }> {}
export const editEngagementCheckFailed = (pathwayId: number): IEditEngagementCheckFailed => ({
  type: EDIT_ENGAGEMENT_CHECK_FAILED,
  payload: { pathwayId },
});

export interface IDeleteEngagementCheck
  extends IReduxAction<
    typeof DELETE_ENGAGEMENT_CHECK,
    { pathwayId: number; engagementCheckId: number }
  > {}
export const deleteEngagementCheck = (
  pathwayId: number,
  engagementCheckId: number,
): IDeleteEngagementCheck => ({
  type: DELETE_ENGAGEMENT_CHECK,
  payload: { pathwayId, engagementCheckId },
});

export interface IDeleteEngagementCheckSuccess
  extends IReduxAction<
    typeof DELETE_ENGAGEMENT_CHECK_SUCCESS,
    { pathwayId: number; engagementCheckId: number }
  > {}
export const deleteEngagementCheckSuccess = (
  pathwayId: number,
  engagementCheckId: number,
): IDeleteEngagementCheckSuccess => ({
  type: DELETE_ENGAGEMENT_CHECK_SUCCESS,
  payload: { pathwayId, engagementCheckId },
});

export interface IDeleteEngagementCheckFailed
  extends IReduxAction<typeof DELETE_ENGAGEMENT_CHECK_FAILED, { pathwayId: number }> {}
export const deleteEngagementCheckFailed = (pathwayId: number): IDeleteEngagementCheckFailed => ({
  type: DELETE_ENGAGEMENT_CHECK_FAILED,
  payload: { pathwayId },
});

export interface IFetchEngagementChecks
  extends IReduxAction<
    typeof FETCH_ENGAGEMENT_CHECKS,
    { pathwayId: number; isSharedPathway?: boolean }
  > {}
export const fetchEngagementChecks = (
  pathwayId: number,
  isSharedPathway?: boolean,
): IFetchEngagementChecks => ({
  type: FETCH_ENGAGEMENT_CHECKS,
  payload: { pathwayId, isSharedPathway },
});

export interface IFetchEngagementChecksSuccess
  extends IReduxAction<
    typeof FETCH_ENGAGEMENT_CHECKS_SUCCESS,
    { pathwayId: number; engagementChecks: any[] }
  > {}
export const fetchEngagementChecksSuccess = (
  pathwayId: number,
  engagementChecks: any[],
): IFetchEngagementChecksSuccess => ({
  type: FETCH_ENGAGEMENT_CHECKS_SUCCESS,
  payload: { pathwayId, engagementChecks },
});

export interface IFetchEngagementChecksFailed
  extends IReduxAction<typeof FETCH_ENGAGEMENT_CHECKS_FAILED, { pathwayId: number }> {}
export const fetchEngagementChecksFailed = (pathwayId: number): IFetchEngagementChecksFailed => ({
  type: FETCH_ENGAGEMENT_CHECKS_FAILED,
  payload: { pathwayId },
});

export interface IFetchPathwaySnapshots
  extends IReduxAction<typeof FETCH_PATHWAY_SNAPSHOTS, { pathwayId: number }> {}
export const fetchPathwaySnapshots = (pathwayId: number): IFetchPathwaySnapshots => ({
  type: FETCH_PATHWAY_SNAPSHOTS,
  payload: { pathwayId },
});

export interface IFetchPathwaySnapshotsSuccess
  extends IReduxAction<typeof FETCH_PATHWAY_SNAPSHOTS_SUCCESS, { snapshots: IPathwaySnapshot[] }> {}
export const fetchPathwaySnapshotsSuccess = (
  snapshots: IPathwaySnapshot[],
): IFetchPathwaySnapshotsSuccess => ({
  type: FETCH_PATHWAY_SNAPSHOTS_SUCCESS,
  payload: { snapshots },
});

export interface IFetchPathwaySnapshotsFailed
  extends IReduxAction<typeof FETCH_PATHWAY_SNAPSHOTS_FAILED, {}> {}
export const fetchPathwaySnapshotsFailed = (): IFetchPathwaySnapshotsFailed => ({
  type: FETCH_PATHWAY_SNAPSHOTS_FAILED,
  payload: {},
});

export interface ICreatePathwaySnapshot
  extends IReduxAction<
    typeof CREATE_PATHWAY_SNAPSHOT,
    {
      pathwayId: number;
      snapshotData: IPathwaySnapshotData;
      makeShared?: boolean;
      sharedWith?: string[];
    }
  > {}
export const createPathwaySnapshot = (
  pathwayId: number,
  snapshotData: IPathwaySnapshotData,
  makeShared?: boolean,
  sharedWith?: string[],
): ICreatePathwaySnapshot => ({
  type: CREATE_PATHWAY_SNAPSHOT,
  payload: { pathwayId, snapshotData, makeShared, sharedWith },
});

export interface ICreatePathwaySnapshotSuccess
  extends IReduxAction<typeof CREATE_PATHWAY_SNAPSHOT_SUCCESS, { snapshot: IPathwaySnapshot }> {}
export const createPathwaySnapshotSuccess = (
  snapshot: IPathwaySnapshot,
): ICreatePathwaySnapshotSuccess => ({
  type: CREATE_PATHWAY_SNAPSHOT_SUCCESS,
  payload: { snapshot },
});

export interface ICreatePathwaySnapshotFailed
  extends IReduxAction<typeof CREATE_PATHWAY_SNAPSHOT_FAILED, {}> {}
export const createPathwaySnapshotFailed = (): ICreatePathwaySnapshotFailed => ({
  type: CREATE_PATHWAY_SNAPSHOT_FAILED,
  payload: {},
});

export interface ISharePathwaySnapshot
  extends IReduxAction<
    typeof SHARE_PATHWAY_SNAPSHOT,
    { pathwayId: number; snapshotId: number; sharedWith?: string[] }
  > {}
export const sharePathwaySnapshot = (
  pathwayId: number,
  snapshotId: number,
  sharedWith?: string[],
): ISharePathwaySnapshot => ({
  type: SHARE_PATHWAY_SNAPSHOT,
  payload: { pathwayId, snapshotId, sharedWith },
});

export interface ISharePathwaySnapshotSuccess
  extends IReduxAction<typeof SHARE_PATHWAY_SNAPSHOT_SUCCESS, { snapshotId: number }> {}
export const sharePathwaySnapshotSuccess = (snapshotId: number): ISharePathwaySnapshotSuccess => ({
  type: SHARE_PATHWAY_SNAPSHOT_SUCCESS,
  payload: { snapshotId },
});

export interface ISharePathwaySnapshotFailed
  extends IReduxAction<typeof SHARE_PATHWAY_SNAPSHOT_FAILED, {}> {}
export const sharePathwaySnapshotFailed = (): ISharePathwaySnapshotFailed => ({
  type: SHARE_PATHWAY_SNAPSHOT_FAILED,
  payload: {},
});

export interface IUnsharePathwaySnapshot
  extends IReduxAction<
    typeof UNSHARE_PATHWAY_SNAPSHOT,
    { pathwayId: number; snapshotId: number }
  > {}
export const unsharePathwaySnapshot = (
  pathwayId: number,
  snapshotId: number,
): IUnsharePathwaySnapshot => ({
  type: UNSHARE_PATHWAY_SNAPSHOT,
  payload: { pathwayId, snapshotId },
});

export interface IUnsharePathwaySnapshotSuccess
  extends IReduxAction<typeof UNSHARE_PATHWAY_SNAPSHOT_SUCCESS, { snapshotId: number }> {}
export const unsharePathwaySnapshotSuccess = (
  snapshotId: number,
): IUnsharePathwaySnapshotSuccess => ({
  type: UNSHARE_PATHWAY_SNAPSHOT_SUCCESS,
  payload: { snapshotId },
});

export interface IUnsharePathwaySnapshotFailed
  extends IReduxAction<typeof UNSHARE_PATHWAY_SNAPSHOT_FAILED, {}> {}
export const unsharePathwaySnapshotFailed = (): IUnsharePathwaySnapshotFailed => ({
  type: UNSHARE_PATHWAY_SNAPSHOT_FAILED,
  payload: {},
});
