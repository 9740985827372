import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import DetailForm from '@components/DetailForm';
import DetailRow from '@components/DetailRow';
import AppUserPathwaysProgress from '@components/AppUserPathwaysProgress';
import IconButton from '@components/IconButton';
import { Card, ColumnGroup, Column } from '@cards/Card';
import { selectAppUser } from '@redux/appUsers/reducers';
import { fetchPathways } from '@pathways/redux/pathways/actions';
import { selectPathways } from '@pathways/redux/pathways/reducers';
import { fetchIndexEvents } from '@pathways/redux/indexEvents/actions';
import { selectIndexEvents } from '@pathways/redux/indexEvents/reducers';
import { extractSlugFromTypeSlug } from '@pathways/redux/indexEvents/utils';
import { fetchAppUserJourneys, fetchAppUsersPathways } from '../../redux/appUserPathways/actions';
import {
  selectJourneysForAppUser,
  selectPathwaysForAppUserWithOriginals,
} from '../../redux/appUserPathways/reducers';
import './style.less';
import { Empty } from 'antd';

function JourneyOverview({ appUserId, journeyId }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [pathwaysLoading] = useSelector(selectPathways);
  const [appUserLoading, appUser] = useSelector(selectAppUser(appUserId));
  const [journeysLoading, journeys] = useSelector(selectJourneysForAppUser(appUser));
  const [appUserPathwaysLoading, appUserPathways] = useSelector(
    selectPathwaysForAppUserWithOriginals(appUser),
  );
  const [indexEventTypesLoading, indexEventTypes] = useSelector(selectIndexEvents);
  const { i18n, t } = useTranslation();

  const loading =
    appUserLoading ||
    journeysLoading ||
    appUserPathwaysLoading ||
    indexEventTypesLoading ||
    pathwaysLoading;

  useEffect(() => {
    dispatch(fetchAppUserJourneys(appUserId));
    dispatch(fetchAppUsersPathways());
    dispatch(fetchIndexEvents());
    dispatch(fetchPathways());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const journey = useMemo(() => (journeys || []).find(({ id }) => id === journeyId), [
    journeys,
    journeyId,
  ]);

  const pathwaysForJourney = useMemo(
    () =>
      appUserPathways?.filter(
        ({ journeyId: pathwayJourneyId }) => pathwayJourneyId === journeyId,
      ) || [],
    [appUserPathways, journeyId],
  );

  /**
   * this is needed to account for Index Events that are part of a journey but
   * don't have a value set currently. The pathways API only allows configuring
   * of Index Events that have a value so the Index Events with no values won't show
   * up here without it.
   */
  const indexEventTypesForJourney = useMemo(() => {
    const indexEventSlugs = new Set();
    pathwaysForJourney.forEach(pathway => {
      if (pathway?.originalPathway?.indexEvents) {
        pathway.originalPathway.indexEvents.forEach(({ eventTypeSlug }) =>
          indexEventSlugs.add(eventTypeSlug),
        );
      }
    });

    return indexEventTypes.filter(({ slug }) => indexEventSlugs.has(slug));
  }, [indexEventTypes, pathwaysForJourney]);

  const indexEventDates = useMemo(
    () =>
      (journey?.indexEvents || []).reduce(
        (prev, indexEvent) => ({
          ...prev,
          [indexEvent.eventTypeSlug]: indexEvent.value,
        }),
        {},
      ),
    [journey],
  );

  const languageCode = useMemo(() => i18n.language.replace(/-.*/, ''), [i18n]);

  return (
    <Card
      className="journey-details-card"
      extra={[
        <IconButton
          icon={faEdit}
          tooltip={t('cards:JourneyOverview.editButtonTooltip')}
          onClick={() => history.push(`/patients/individuals/${appUserId}/journey/edit`)}
        />,
      ]}
      title={t('cards:JourneyDetails.title')}
      loading={loading}
    >
      <ColumnGroup>
        <Column>
          {indexEventTypesForJourney.length ? (
            <DetailForm>
              <h4>{t('cards:JourneyDetails.indexEvents')}</h4>
              {indexEventTypesForJourney.map(indexEvent => (
                <DetailRow
                  key={indexEvent.id}
                  label={indexEvent.translatedNames[languageCode] || indexEvent.name}
                  value={
                    indexEventDates[extractSlugFromTypeSlug(indexEvent.slug)]
                      ? moment(indexEventDates[extractSlugFromTypeSlug(indexEvent.slug)]).format(
                          'LL',
                        )
                      : t('cards:JourneyDetails.noDate')
                  }
                />
              ))}
            </DetailForm>
          ) : (
            <Empty description={t('cards:JourneyDetails.noIndexEvents')} />
          )}
        </Column>
        <Column>
          <h4>{t('cards:JourneyDetails.pathways')}</h4>
          <AppUserPathwaysProgress appUser={appUser} />
        </Column>
      </ColumnGroup>
    </Card>
  );
}

export default JourneyOverview;
