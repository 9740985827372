import React from 'react';
import { Icon, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { contentTypes, contentTypesList } from '@utils/contentTypes';
import moment from 'moment';
import { pathway } from '@assets/icons';
import { selectForm } from '@redux/forms/reducers';
import { FORM, QUESTIONNAIRE, TASK, ALERT, REMINDER } from '@utils/contentTypes';
import {
  getEventForRuleExecution,
  filterEntriesByEvent,
  FORM_SUBMISSION,
  RULE_EXECUTION,
  STAGE_TRANSITION,
  UNSUPPORTED_ACTION,
  ADHOC_MESSAGE,
  OTHER,
  FLAG_RAISED,
  FLAG_RESOLVED,
} from './utils';
import DateFilter from '@components/DateFilter';
import ActionedContentColumn from './ActionedContentColumn';

function extractFormId(formDataId) {
  const extractedFormId = /[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}/.exec(
    formDataId,
  );

  if (extractedFormId) {
    return extractedFormId[0];
  }

  return false;
}

function FormSubmissionEvent({ formId }) {
  const { t } = useTranslation();
  const [loading, form] = useSelector(selectForm(formId));

  if (loading) return null;
  if (!form) return t('cards:JourneyTimeline.events.submittedForm');

  switch (form.metadata.type) {
    case TASK:
      return t('cards:JourneyTimeline.events.submittedTask');
    case QUESTIONNAIRE:
      return t('cards:JourneyTimeline.events.submittedQuestionnaire');
    case FORM:
    default:
      return t('cards:JourneyTimeline.events.submittedForm');
  }
}

function FormSubmissionTrigger({ formId }) {
  const { t } = useTranslation();
  const [loading, form] = useSelector(selectForm(formId));
  if (loading) return null;

  // TODO: link these to the form submission results
  switch (form?.metadata?.type) {
    case QUESTIONNAIRE:
      return t('cards:JourneyTimeline.triggers.questionnaire');
    case TASK:
      return t('cards:JourneyTimeline.triggers.task');
    default:
      if (form?.name === 'questionnaire 2') console.log('default:', form?.metadata?.type);
      return t('cards:JourneyTimeline.triggers.form');
  }
}

export default (t, appUserId, pathwayDataById = {}) => {
  return [
    {
      dataIndex: 'event_datetime',
      key: 'date',
      title: t('cards:JourneyTimeline.columns.date'),
      render: datetime => moment(datetime).format('LLL'),
      sorter: (a, b) => a.event_datetime.localeCompare(b.event_datetime),
      filterDropdown: DateFilter.filterDropdown,
      onFilter: (value, record) => DateFilter.onFilter(value, record.event_datetime),
    },
    {
      filters: [
        {
          text: t('cards:JourneyTimeline.filters.ruleExecutions'),
          value: t('cards:JourneyTimeline.filters.ruleExecutions'),
          children: contentTypesList.map(contentType => ({
            text: t(contentTypes[contentType].label),
            value: contentType,
          })),
        },
        {
          text: t('cards:JourneyTimeline.filters.stageTransitions'),
          value: t('cards:JourneyTimeline.filters.stageTransitions'),
          children: [
            {
              text: t('cards:JourneyTimeline.events.stageTransition'),
              value: STAGE_TRANSITION,
            },
          ],
        },
        {
          text: t('cards:JourneyTimeline.filters.formSubmissions'),
          value: t('cards:JourneyTimeline.filters.formSubmissions'),
          children: [
            {
              text: t('cards:JourneyTimeline.events.submittedForm'),
              value: 'form_submitted',
            },
            {
              text: t('cards:JourneyTimeline.events.submittedQuestionnaire'),
              value: 'questionnaire_submitted',
            },
            {
              text: t('cards:JourneyTimeline.events.submittedTask'),
              value: 'task_submitted',
            },
          ],
        },
        {
          text: t('cards:JourneyTimeline.filters.flagRaised'),
          value: FLAG_RAISED,
        },
        {
          text: t('cards:JourneyTimeline.filters.flagResolved'),
          value: FLAG_RESOLVED,
        },
      ],
      key: 'action',
      title: t('cards:JourneyTimeline.columns.event'),
      onFilter: filterEntriesByEvent,
      render: record => {
        switch (record.type) {
          case STAGE_TRANSITION:
            return (
              <>
                <Icon component={pathway} /> {t('cards:JourneyTimeline.events.stageTransition')}
              </>
            );
          case RULE_EXECUTION:
            return getEventForRuleExecution(record, t);
          case FORM_SUBMISSION:
            const extractedFormId = extractFormId(record.data.form_id);

            if (extractedFormId) {
              return <FormSubmissionEvent formId={extractedFormId} />;
            }

            return t('cards:JourneyTimeline.events.submittedForm');
          case ADHOC_MESSAGE:
            switch (record.data.metadata?.type) {
              case ALERT:
                return t('cards:JourneyTimeline.events.adhocAlert');
              case REMINDER:
                return t('cards:JourneyTimeline.events.adhocReminder');
              default:
                return t('cards:JourneyTimeline.events.adhocMessage');
            }
          case OTHER:
            if (record.data.event_type === 'connect_to_organisation') {
              return t('cards:JourneyTimeline.events.connect');
            } else if (record.data.engagement_check) {
              return t('cards:JourneyTimeline.events.engagementCheck');
            } else {
              return t('cards:JourneyTimeline.events.disconnect');
            }
          case FLAG_RAISED:
            return t('cards:JourneyTimeline.events.flagRaised');
          case FLAG_RESOLVED:
            return t('cards:JourneyTimeline.events.flagResolved');
          default:
            return UNSUPPORTED_ACTION;
        }
      },
    },
    {
      title: t('cards:JourneyTimeline.columns.audience.title'),
      key: 'id',
      render: record => {
        if (record.audience_type)
          return t(`cards:JourneyTimeline.columns.audience.${record.audience_type}`);
        return '';
      },
    },
    {
      dataIndex: ['data', 'pathway_id'],
      filters: Object.values(pathwayDataById).map(({ name: pathwayName }) => ({
        text: pathwayName,
        value: pathwayName,
      })),
      key: 'pathway',
      title: t('cards:JourneyTimeline.columns.pathway'),
      render: pathwayId =>
        pathwayDataById[pathwayId]?.name ?? t('cards:JourneyTimeline.notApplicable'),
      onFilter: (value, record) => value === pathwayDataById[record.data.pathway_id]?.name,
    },
    {
      filters: [
        { text: t('cards:JourneyTimeline.triggers.stage'), value: STAGE_TRANSITION },
        { text: t('cards:JourneyTimeline.triggers.rule'), value: RULE_EXECUTION },
        { text: t('cards:JourneyTimeline.triggers.form'), value: FORM_SUBMISSION },
      ],
      key: 'trigger',
      title: t('cards:JourneyTimeline.columns.trigger'),
      onFilter: (value, record) => value === record.type,
      render: record => {
        switch (record.type) {
          case STAGE_TRANSITION:
            return t('cards:JourneyTimeline.triggers.stage');
          case RULE_EXECUTION:
            return (
              <Tooltip title={record.data.rule_name}>
                {record.data.entry_type === 'check'
                  ? t('cards:JourneyTimeline.triggers.engagementCheck')
                  : t('cards:JourneyTimeline.triggers.rule')}
              </Tooltip>
            );
          case FORM_SUBMISSION:
            const extractedFormId = extractFormId(record.data.form_id);

            if (extractedFormId) {
              return <FormSubmissionTrigger formId={extractedFormId} />;
            }

            return t('cards:JourneyTimeline.triggers.form');
          case ADHOC_MESSAGE:
            switch (record.data.metadata?.type) {
              case ALERT:
                return t('cards:JourneyTimeline.triggers.adhocAlert');
              case REMINDER:
                return t('cards:JourneyTimeline.triggers.adhocReminder');
              default:
                return t('cards:JourneyTimeline.triggers.adhocMessage');
            }
          case OTHER:
            if (record.data.engagement_check) {
              if (record.data.event) {
                return `${record.data.debug ? '[DEBUG] ' : ''} -> Check ID ${
                  record.data.engagement_check.id
                } ; Content ID: ${record.data.event.content_id}`;
              } else {
                return `Check ID ${record.data.engagement_check.id}`;
              }
              // return `${record.data.event.content_type}/${record.data.event.content_id}`;
            }
            return t('cards:JourneyTimeline.triggers.userAction');
          case FLAG_RAISED:
          case FLAG_RESOLVED:
            return t('cards:JourneyTimeline.triggers.dashboardUser');
          default:
            return UNSUPPORTED_ACTION;
        }
      },
    },
    {
      key: 'actionedItem',
      title: t('cards:JourneyTimeline.columns.actionedItem'),
      render: record => {
        return (
          <ActionedContentColumn
            t={t}
            pathwayDataById={pathwayDataById}
            record={record}
            appUserId={appUserId}
          />
        );
      },
    },
  ];
};
