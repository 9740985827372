import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal, Select, Popover, Tag } from 'antd';
import moment from 'moment';
import {
  faCheckCircle,
  faCopy,
  faTimesCircle,
  faTrashAlt,
  faCodeBranch,
  faShareAlt,
  faInfoCircle,
  // faFileArchive,
  // faUser,
} from '@fortawesome/pro-regular-svg-icons';
import { Column, ColumnGroup } from '@cards/Card';
import PathwayStagesTableCard from '@pathways/cards/Pathways/PathwayStagesTable';
import confirmDuplicateModal from '@pathways/cards/Pathways/PathwayList/confirmDuplicateModal';
import WrappedCreateSnapshotModal from '@pathways/cards/Pathways/PathwayDetails/createSnapshotModal';
import SharingSettingsModal from '@pathways/cards/Pathways/PathwayDetails/sharingSettingsModal';
import PathwayDetailsCard from '@pathways/cards/Pathways/PathwayDetails/Card';
import IndexEventTriggeredRulesCard from '@pathways/cards/Pathways/IndexEventTriggeredRules';
// import IndividualsCard from '@pathways/cards/Pathways/Individuals';
import EngagementChecksCard from '@pathways/cards/Pathways/EngagementChecks';
// import IndividualAccessModal from '@components/IndividualAccessModal';
import IconButton from '@components/IconButton';
import { waitForStoreUpdate } from '@utils';
import { usePageActions } from '@hooks';
import { selectPathway, selectPathwaySnapshots } from '@pathways/redux/pathways/reducers';
import { fetchHospital } from '@redux/hospitals/actions';
// import { selectHospital } from '@redux/hospitals/reducers';
import EditButton from '@components/EditButton';
// import { addPathwayToAppUsers } from '@pathways/redux/appUserPathways/actions';
import {
  deletePathway,
  duplicatePathway,
  publishPathways,
  unpublishPathways,
  createPathwaySnapshot,
  sharePathwaySnapshot,
  unsharePathwaySnapshot,
  fetchPathwaySnapshots,
} from '@pathways/redux/pathways/actions';
import { fetchPathwayRulesWhatDetail } from '@pathways/redux/rules/actions';
import { fetchSharedPathwaySnapshots } from '@pathways/redux/sharedPathways/actions';
import { selectSharedPathway } from '@pathways/redux/sharedPathways/reducers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { selectOrganisationSlug } from '../../../organisation/redux/selectors';
import { organisationSlug as primaryOrgSlug, restrictSharedSnapshotsCreation } from 'settings';

import './style.less';

// function determinePathwayEditable(pathwayId, protectedPathways = [], loading) {
//   if (protectedPathways.includes(Number(pathwayId)) || loading) return false;

//   return true;
// }

function PathwayDetails({ history, match }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = useStore();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [visibleModalType, setVisibleModalType] = useState(null);
  const orgSlug = useSelector(selectOrganisationSlug());
  const [, selectorPathway] = useSelector(selectPathway(match.params.id));
  const snapshots = useSelector(selectPathwaySnapshots(match.params.id));
  const sharedPathway = useSelector(selectSharedPathway(Number(match.params.id)));
  const [pathway, setPathway] = useState(selectorPathway);
  const [parentPathwayId, setParentPathwayId] = useState(null);
  const [selectedOrgIds, setSelectedOrgIds] = useState([]);
  // const dashboardUserProfile = useSelector(state => state.login.user.profile);
  // const [hospitalLoading, hospital] = useSelector(selectHospital(dashboardUserProfile?.hospitalId));
  // const isEditable = true;
  // Old code for determining if pathway belongs to hospital which gives them the ability to edit it
  // useMemo(
  //   () =>
  //     determinePathwayEditable(
  //       match.params.id,
  //       hospital?.pathways,
  //       pathwayLoading || hospitalLoading,
  //     ),
  //   [match, hospital, pathwayLoading, hospitalLoading],
  // );

  const [isEditable, setIsEditable] = useState(false);
  const isSharedPathway = match.path.includes('shared');

  const openModal = useCallback(modalType => setVisibleModalType(modalType), []);

  const onCancel = useCallback(() => {
    setVisibleModalType(null);
  }, []);

  useEffect(() => {
    dispatch(fetchHospital());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchPathwaySnapshots(Number(match.params.id)));
  }, [dispatch, match.params.id]);

  const snapshot = match.params.snapshot || '0';

  useEffect(() => {
    if (!sharedPathway?.id && isSharedPathway) {
      dispatch(fetchSharedPathwaySnapshots());
    }
  }, [dispatch, sharedPathway, isSharedPathway]);

  useEffect(() => {
    if (pathway?.id) {
      dispatch(fetchPathwayRulesWhatDetail(pathway.id, isSharedPathway));
    }
  }, [dispatch, pathway, isSharedPathway]);

  useEffect(() => {
    if (snapshot !== '0') {
      const selectedSnapshot = snapshots.find(snap => snap.snapshotNumber === Number(snapshot));
      setPathway(selectedSnapshot);
      setParentPathwayId(selectedSnapshot?.url.split('/pathways/')[1].split('/')[0]);
      setIsEditable(false);
    } else if (isSharedPathway && sharedPathway?.id) {
      setPathway(sharedPathway);
      setIsEditable(false);
    } else {
      setPathway(selectorPathway);
      setIsEditable(true);
    }
  }, [
    selectorPathway,
    snapshot,
    snapshots,
    isSharedPathway,
    sharedPathway,
    pathway,
    dispatch,
    match,
  ]);

  const handleShareSnapshot = useCallback(() => {
    if (parentPathwayId && pathway?.id) {
      dispatch(sharePathwaySnapshot(parentPathwayId, pathway.id));
    }
  }, [dispatch, parentPathwayId, pathway]);

  const handleUnshareSnapshot = useCallback(() => {
    if (parentPathwayId && pathway?.id) {
      dispatch(unsharePathwaySnapshot(parentPathwayId, pathway.id));
    }
  }, [dispatch, parentPathwayId, pathway]);

  const handleUpdatePathwaySnapshotOrgs = useCallback(
    share => {
      if (parentPathwayId && pathway?.id) {
        if (share) {
          dispatch(sharePathwaySnapshot(parentPathwayId, pathway.id, selectedOrgIds));
        } else {
          dispatch(unsharePathwaySnapshot(parentPathwayId, pathway.id));
        }
      }
      setVisibleModalType(null);
    },
    [dispatch, parentPathwayId, pathway, selectedOrgIds],
  );

  useEffect(() => {
    if (pathway?.sharedWith?.length) {
      setSelectedOrgIds(pathway.sharedWith);
    }
  }, [pathway]);

  const modalTypes = useMemo(
    () => ({
      // Commented out as workflow to do this isn't complete and doesn't deal ith Journeys
      // 'add-to-user': {
      //   title: '',
      //   okText: '',
      //   cancelText: t('common:buttons.cancel'),
      //   onOk: appUserIds => {
      //     dispatch(addPathwayToAppUsers(appUserIds, pathway.id));
      //     setIsSubmitting(true);
      //     waitForStoreUpdate(store, selectPathway(match.params.id)).then(() => {
      //       onCancel();
      //       setIsSubmitting(false);
      //     });
      //   },
      //   onCancel,
      // },
      'copy-pathway': {
        title: '',
        okText: '',
        cancelText: t('common:buttons.cancel'),
        onOk: () => {
          dispatch(duplicatePathway(pathway.id));
          setIsSubmitting(true);
          return waitForStoreUpdate(store, state => [state.pathways.duplicating]).then(() => {
            onCancel();
            setIsSubmitting(false);
          });
        },
        onCancel,
      },
      'publish-pathway': {
        title:
          pathway && pathway.isActive
            ? t('pathways:ProcedurePathways.details.modals.confirmUnpublish', {
                pathwayName: pathway.name,
              })
            : t('pathways:ProcedurePathways.details.modals.confirmPublish', {
                pathwayName: pathway ? pathway.name : '',
              }),
        okText:
          pathway && pathway.isActive ? t('common:buttons.unpublish') : t('common:buttons.publish'),
        cancelText: t('common:buttons.cancel'),
        onOk: () => {
          pathway.isActive
            ? dispatch(unpublishPathways([pathway.id]))
            : dispatch(publishPathways([(pathway && pathway.id) || '']));
          onCancel();
          return waitForStoreUpdate(store, selectPathway(match.params.id)).then(() => {
            setIsSubmitting(false);
          });
        },
        onCancel,
      },
      'delete-pathway': {
        title: t('pathways:ProcedurePathways.details.modals.confirmDelete', {
          pathwayName: pathway ? pathway.name : '',
        }),
        okText: t('common:buttons.delete'),
        cancelText: t('common:buttons.cancel'),
        onOk: () => {
          dispatch(deletePathway(pathway.id));
          return waitForStoreUpdate(store, selectPathway(match.params.id));
        },
        onCancel,
      },
      'share-snapshot': {
        title: t('pathways:ProcedurePathways.details.modals.confirmShare', {
          pathwayName: pathway?.name || '',
          snapshotName: pathway?.snapshotName || '',
        }),
        okText: t('common:buttons.shareSnapshot'),
        cancelText: t('common:buttons.cancel'),
        onOk: () => {
          handleShareSnapshot();
          onCancel();
        },
        onCancel,
      },
      'unshare-snapshot': {
        title: t('pathways:ProcedurePathways.details.modals.confirmUnshare', {
          pathwayName: pathway?.name || '',
          snapshotName: pathway?.snapshotName || '',
        }),
        okText: t('common:buttons.unshareSnapshot'),
        cancelText: t('common:buttons.cancel'),
        onOk: () => {
          handleUnshareSnapshot();
          onCancel();
        },
        onCancel,
      },
      'sharing-settings': {
        title: t('pathways:ProcedurePathways.details.modals.confirmShare', {
          pathwayName: pathway?.name || '',
          snapshotName: pathway?.snapshotName || '',
        }),
      },
    }),
    [dispatch, match, pathway, store, onCancel, t, handleShareSnapshot, handleUnshareSnapshot],
  );

  useEffect(() => {
    if (visibleModalType === 'copy-pathway') {
      if (!isSubmitting) {
        const { onCancel, onOk } = modalTypes[visibleModalType];
        confirmDuplicateModal(t, onOk, onCancel);
      }
    } else if (visibleModalType === 'add-to-user') {
      // do nothing here
    } else if (visibleModalType === 'create-snapshot') {
      // do nothing here
    } else if (visibleModalType === 'sharing-settings' && orgSlug === primaryOrgSlug) {
      // do nothing here, as SharingSettingsModal is used in this case
    } else if (visibleModalType) {
      Modal.confirm(modalTypes[visibleModalType]);
    }
  }, [isSubmitting, modalTypes, t, visibleModalType, orgSlug]);

  const { setActions, setTitle } = usePageActions({
    actions: [],
    showBack: true,
    title: '',
  });

  useEffect(() => {
    setTitle(
      <div className="pathway-details-title-container">
        <div>
          {pathway
            ? pathway.name?.length > 50
              ? `${pathway.name.slice(0, 50)}...`
              : pathway.name
            : ''}
        </div>
        {pathway?.name && !isSharedPathway && (
          <>
            <div className="pathway-snapshots-dropdown-container">
              <Select
                defaultValue={snapshot || '0'}
                onChange={snapshot =>
                  history.push(`/procedure/pathways/${parentPathwayId || pathway.id}/${snapshot}`)
                }
                dropdownClassName="pathway-snapshots-dropdown"
                className="pathway-snapshots-dropdown-select"
              >
                <Select.Option key="0" value="0" className="active-snapshot-option">
                  {t('pathways:ProcedurePathways.createSnapshot.active')}
                </Select.Option>
                {snapshots.length > 0 && (
                  <Select.OptGroup label={t('pathways:ProcedurePathways.createSnapshot.snapshots')}>
                    {snapshots.map(snap => (
                      <Select.Option
                        key={snap.snapsotNumber?.toString()}
                        value={snap.snapshotNumber?.toString()}
                      >
                        <p className="snapshot-name">{snap.snapshotName}</p>
                        <p className="snapshot-creation-date-text">
                          {t('pathways:ProcedurePathways.createSnapshot.creationDate')}{' '}
                          {moment(snap.creation).format('D MMM YY')}
                        </p>
                        {snap.isSharedSnapshot ? (
                          <div className="shared-tag">
                            <Tag color="#ccc">
                              <FontAwesomeIcon icon={faShareAlt} />{' '}
                              {t('pathways:ProcedurePathways.createSnapshot.shared')}
                            </Tag>
                          </div>
                        ) : null}
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                )}
              </Select>
            </div>
            <div className="pathway-snapshots-popover-container">
              <Popover
                content={
                  <>
                    <p>{t('pathways:ProcedurePathways.createSnapshot.explanation1')}</p>
                    <p>{t('pathways:ProcedurePathways.createSnapshot.explanation2')}</p>
                  </>
                }
                title={t('pathways:ProcedurePathways.createSnapshot.pathwaySnapshots')}
                trigger="click"
                placement="top"
                overlayClassName="pathway-snapshots-popover"
              >
                <IconButton
                  icon={faInfoCircle}
                  tooltip={t('common:buttons.info')}
                  onClick={() => {}}
                />
              </Popover>
            </div>
          </>
        )}
      </div>,
    );
  }, [pathway?.id, pathway?.isSharedSnapshot, snapshots?.length]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setActions([
      // Commented out as workflow to do this isn't complete and doesn't deal ith Journeys
      // <IconButton
      //   icon={faUser}
      //   key="add-to-user"
      //   tooltip={t('common:buttons.applyIndividualAccess')}
      //   onClick={() => openModal('add-to-user')}
      // />,
      // <IconButton
      //   disabled={!isEditable}
      //   icon={faFileArchive}
      //   key="manage-content-updates"
      //   tooltip={t('common:buttons.manageContentUpdates')}
      //   onClick={() => history.push(`${match.url}/manage-content-updates`)}
      // />,
      <>
        {orgSlug === primaryOrgSlug && (
          <IconButton
            disabled={isEditable || isSharedPathway}
            icon={faShareAlt}
            key="sharing-settings"
            tooltip={t('common:buttons.sharingSettings')}
            onClick={() => openModal('sharing-settings')}
          />
        )}
      </>,
      <>
        {!(restrictSharedSnapshotsCreation && orgSlug !== primaryOrgSlug) &&
          orgSlug !== primaryOrgSlug && (
            <IconButton
              disabled={isEditable || isSharedPathway}
              icon={faShareAlt}
              key="share-snapshot"
              tooltip={
                pathway?.isSharedSnapshot
                  ? t('common:buttons.unshareSnapshot')
                  : snapshot && pathway?.isSnapshot
                  ? t('common:buttons.shareSnapshot')
                  : t('common:buttons.selectSnapshot')
              }
              onClick={() =>
                pathway?.isSharedSnapshot
                  ? openModal('unshare-snapshot')
                  : openModal('share-snapshot')
              }
            />
          )}
      </>,
      <IconButton
        disabled={!isEditable}
        icon={faCodeBranch}
        key="create-snapshot"
        tooltip={t('common:buttons.createSnapshot')}
        onClick={() => {
          setSelectedOrgIds([]);
          openModal('create-snapshot');
        }}
      />,
      <IconButton
        disabled={isSharedPathway}
        icon={faCopy}
        key="copy-pathway"
        tooltip={t('common:buttons.copy')}
        onClick={() => openModal('copy-pathway')}
      />,
      <IconButton
        disabled={!isEditable}
        icon={pathway && pathway.isActive ? faTimesCircle : faCheckCircle}
        key="publish-pathway"
        tooltip={
          pathway && pathway.isActive ? t('common:buttons.unpublish') : t('common:buttons.publish')
        }
        onClick={() => openModal('publish-pathway')}
      />,
      <IconButton
        disabled={!isEditable}
        icon={faTrashAlt}
        key="delete-pathway"
        tooltip={t('common:buttons.delete')}
        onClick={() => Modal.confirm(modalTypes['delete-pathway'])}
      />,
      <EditButton
        disabled={!isEditable}
        key="edit-pathway"
        onClick={() => history.push(`${match.url}/edit`)}
      />,
    ]);
  }, [pathway, openModal, t, isEditable]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCreateSnapshot = values => {
    dispatch(
      createPathwaySnapshot(
        pathway.id,
        {
          name: values.name,
          description: values.description,
        },
        values.makeShared,
        selectedOrgIds,
      ),
    );

    // history.push(`/procedure/pathways/${pathway.id}/${snapshotNumber}`);
    onCancel();
  };

  return (
    <>
      <ColumnGroup>
        <Column>
          <PathwayStagesTableCard
            pathwayId={match.params.id}
            history={history}
            match={match}
            sharedPathway={sharedPathway?.id ? sharedPathway : pathway}
          />
        </Column>
      </ColumnGroup>
      <ColumnGroup>
        <Column>
          <EngagementChecksCard
            pathwayId={match.params.id}
            disabled={!isEditable}
            isSharedPathway={isSharedPathway}
          />
        </Column>
      </ColumnGroup>
      <ColumnGroup>
        <Column>
          <IndexEventTriggeredRulesCard pathwayId={match.params.id} />
        </Column>
      </ColumnGroup>
      <ColumnGroup>
        <Column>
          <PathwayDetailsCard pathway={pathway} />
        </Column>
        <Column>{/* <IndividualsCard pathwayId={match.params.id} /> */}</Column>
      </ColumnGroup>

      {/* <IndividualAccessModal
        submitting={isSubmitting}
        visible={visibleModalType === 'add-to-user'}
        onCancel={onCancel}
        onSubmit={modalTypes[visibleModalType] && modalTypes[visibleModalType].onOk}
      /> */}

      {visibleModalType === 'sharing-settings' && (
        <SharingSettingsModal
          t={t}
          onCancel={onCancel}
          onOk={handleUpdatePathwaySnapshotOrgs}
          visible={visibleModalType === 'sharing-settings'}
          selectedOrgIds={selectedOrgIds}
          setSelectedOrgIds={setSelectedOrgIds}
          isCurrentlyShared={pathway?.isSharedSnapshot}
        />
      )}

      {visibleModalType === 'create-snapshot' && (
        <WrappedCreateSnapshotModal
          t={t}
          onCancel={onCancel}
          onOk={handleCreateSnapshot}
          visible={visibleModalType === 'create-snapshot'}
          orgSlug={orgSlug}
          primaryOrgSlug={primaryOrgSlug}
          selectedOrgIds={selectedOrgIds}
          setSelectedOrgIds={setSelectedOrgIds}
        />
      )}
    </>
  );
}

export default PathwayDetails;
