import React from 'react';
import { useSelector } from 'react-redux';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Empty, Form } from 'antd';
import moment from 'moment';
import { selectContentTypeIcon } from '@assets/icons';
import { languageFromMetadata, sourceFromMetadata } from '@utils/contentMetadata';
import { selectLanguage, selectOwner } from '@redux/core/reducers';
import DetailRow from '@components/DetailRow';
import SourceIcon from '@components/SourceIcon';
import LanguageIcon from '@components/LanguageIcon';
import None from '@components/None';
import PublishedStatusIcon from '@components/PublishedStatusIcon';
import { Card } from '../../Card';
import './styles.less';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  layout: 'vertical',
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

function DocumentDetailsCard({ document, documentTranslationKey, loading, title, ...props }) {
  const { t } = useTranslation();
  const findLanguage = useSelector(languageFromMetadata);
  const findSource = useSelector(sourceFromMetadata);
  const language = useSelector(selectLanguage(findLanguage(document)));
  const source = useSelector(selectOwner(findSource(document)));

  return (
    <Card.Half className="document-details" noPadding loading={loading} title={t(title)} {...props}>
      {document === undefined ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <Form {...formItemLayout} className="detail-form">
          <DetailRow
            label={t('cards:DocumentDetail.labels.type')}
            value={
              <>
                <FontAwesomeIcon size="lg" icon={selectContentTypeIcon(documentTranslationKey)} />
                {t(`common:contentType.${documentTranslationKey}`)}
              </>
            }
          />

          <DetailRow label={t('cards:DocumentDetail.labels.title')} value={document.name} />

          <DetailRow
            label={t('cards:DocumentDetail.labels.version')}
            value={document.latest_version?.name}
          />

          <DetailRow
            label={t('cards:DocumentDetail.labels.status')}
            value={<PublishedStatusIcon published={document.published} showText />}
          />

          <DetailRow
            label={t('cards:DocumentDetail.labels.created')}
            value={moment(document.latest_version?.created).format('L')}
          />

          <DetailRow
            label={t('cards:DocumentDetail.labels.description')}
            value={document.description || <None />}
          />

          <DetailRow
            label={t('cards:DocumentDetail.labels.source')}
            value={<SourceIcon showText source={source?.key} />}
          />

          <DetailRow
            label={t('cards:DocumentDetail.labels.audienceType')}
            value={t(`common:audienceType.${document.audience_type ?? 'app-user'}`)}
          />

          <DetailRow
            label={t('cards:DocumentDetail.labels.language')}
            value={<LanguageIcon showText language={language?.key} />}
          />
        </Form>
      )}
    </Card.Half>
  );
}

DocumentDetailsCard.propTypes = {
  documentTranslationKey: string,
  title: string,
};

DocumentDetailsCard.defaultProps = {
  documentTranslationKey: 'documents',
  title: 'cards:DocumentDetail.title',
};

export default Form.create({ name: 'documentDetails' })(DocumentDetailsCard);
