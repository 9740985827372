import { IRawRule, IRule } from '@pathways/redux/rules/types';

export const CREATE_PATHWAY = 'CREATE_PATHWAY';
export const CREATE_PATHWAY_FAILURE = 'CREATE_PATHWAY_FAILURE';
export const CREATE_PATHWAY_SUCCESS = 'CREATE_PATHWAY_SUCCESS';
export const DELETE_PATHWAY = 'DELETE_PATHWAY';
export const DELETE_PATHWAY_FAILURE = 'DELETE_PATHWAY_FAILURE';
export const DELETE_PATHWAY_SUCCESS = 'DELETE_PATHWAY_SUCCESS';
export const DELETE_PATHWAYS = 'DELETE_PATHWAYS';
export const DELETE_PATHWAYS_FAILURE = 'DELETE_PATHWAYS_FAILURE';
export const DELETE_PATHWAYS_SUCCESS = 'DELETE_PATHWAYS_SUCCESS';
export const DUPLICATE_PATHWAY = 'DUPLICATE_PATHWAY';
export const DUPLICATE_PATHWAY_SUCCESS = 'DUPLICATE_PATHWAY_SUCCESS';
export const DUPLICATE_PATHWAYS = 'DUPLICATE_PATHWAYS';
export const DUPLICATE_PATHWAYS_SUCCESS = 'DUPLICATE_PATHWAYS_SUCCESS';
export const EDIT_PATHWAY = 'EDIT_PATHWAY';
export const EDIT_PATHWAY_SUCCESS = 'EDIT_PATHWAY_SUCCESS';
export const EDIT_PATHWAY_FAILED = 'EDIT_PATHWAY_FAILED';
export const FETCH_PATHWAYS = 'FETCH_PATHWAYS';
export const FETCHING_PATHWAYS = 'FETCHING_PATHWAYS';
export const FETCH_PATHWAYS_ERROR = 'FETCH_PATHWAYS_ERROR';
export const LOAD_PATHWAYS = 'LOAD_PATHWAYS';
export const PUBLISH_PATHWAYS = 'PUBLISH_PATHWAYS';
export const PUBLISH_PATHWAYS_SUCCESS = 'PUBLISH_PATHWAYS_SUCCESS';
export const UNPUBLISH_PATHWAYS = 'UNPUBLISH_PATHWAYS';
export const UNPUBLISH_PATHWAYS_SUCCESS = 'UNPUBLISH_PATHWAYS_SUCCESS';
export const PROCESS_APPUSER_PATHWAY_NOW = 'PROCESS_APPUSER_PATHWAY_NOW';

export const FETCH_ENGAGEMENT_CHECKS = 'FETCH_ENGAGEMENT_CHECKS';
export const FETCH_ENGAGEMENT_CHECKS_SUCCESS = 'FETCH_ENGAGEMENT_CHECKS_SUCCESS';
export const FETCH_ENGAGEMENT_CHECKS_FAILED = 'FETCH_ENGAGEMENT_CHECKS_FAILED';
export const ADD_ENGAGEMENT_CHECK = 'ADD_ENGAGEMENT_CHECK';
export const ADD_ENGAGEMENT_CHECK_SUCCESS = 'ADD_ENGAGEMENT_CHECK_SUCCESS';
export const ADD_ENGAGEMENT_CHECK_FAILED = 'ADD_ENGAGEMENT_CHECK_FAILED';
export const EDIT_ENGAGEMENT_CHECK = 'EDIT_ENGAGEMENT_CHECK';
export const EDIT_ENGAGEMENT_CHECK_SUCCESS = 'EDIT_ENGAGEMENT_CHECK_SUCCESS';
export const EDIT_ENGAGEMENT_CHECK_FAILED = 'EDIT_ENGAGEMENT_CHECK_FAILED';
export const DELETE_ENGAGEMENT_CHECK = 'DELETE_ENGAGEMENT_CHECK';
export const DELETE_ENGAGEMENT_CHECK_SUCCESS = 'DELETE_ENGAGEMENT_CHECK_SUCCESS';
export const DELETE_ENGAGEMENT_CHECK_FAILED = 'DELETE_ENGAGEMENT_CHECK_FAILED';

export const FETCH_PATHWAY_SNAPSHOTS = 'FETCH_PATHWAY_SNAPSHOTS';
export const FETCH_PATHWAY_SNAPSHOTS_SUCCESS = 'FETCH_PATHWAY_SNAPSHOTS_SUCCESS';
export const FETCH_PATHWAY_SNAPSHOTS_FAILED = 'FETCH_PATHWAY_SNAPSHOTS_FAILED';
export const CREATE_PATHWAY_SNAPSHOT = 'CREATE_PATHWAY_SNAPSHOT';
export const CREATE_PATHWAY_SNAPSHOT_SUCCESS = 'CREATE_PATHWAY_SNAPSHOT_SUCCESS';
export const CREATE_PATHWAY_SNAPSHOT_FAILED = 'CREATE_PATHWAY_SNAPSHOT_FAILED';
export const SHARE_PATHWAY_SNAPSHOT = 'SHARE_PATHWAY_SNAPSHOT';
export const SHARE_PATHWAY_SNAPSHOT_SUCCESS = 'SHARE_PATHWAY_SNAPSHOT_SUCCESS';
export const SHARE_PATHWAY_SNAPSHOT_FAILED = 'SHARE_PATHWAY_SNAPSHOT_FAILED';
export const UNSHARE_PATHWAY_SNAPSHOT = 'UNSHARE_PATHWAY_SNAPSHOT';
export const UNSHARE_PATHWAY_SNAPSHOT_SUCCESS = 'UNSHARE_PATHWAY_SNAPSHOT_SUCCESS';
export const UNSHARE_PATHWAY_SNAPSHOT_FAILED = 'UNSHARE_PATHWAY_SNAPSHOT_FAILED';

// TODO: Import the types from the Pathways Client where possible

export interface IPathwayIndexEvent {
  id: number;
  url: string;
  rules: Array<IRule>;
  eventTypeSlug: string;
}

export interface IStage {
  id: number;
  url: string;
  number: number;
  name: string;
  slug: string;
  description: string;
  rules: IRule[];
  isAdhoc: boolean;
}

export interface IPathwayData {
  name: string;
  description: string;
  stages: IStage[];
  metadata: { [key: string]: any };
  source: string;
  indexEvents: Array<IPathwayIndexEvent>;
  isActive: boolean;
  isDeleted: boolean;
  language: string;
}

export interface IPathway extends IPathwayData {
  id: number;
  url: string;
  numAppUserPathways: number;
  ownerId: string;
  engagementChecks?: {
    loading: boolean;
    engagements: any[];
  };
  isSnapshot: boolean;
  isSharedSnapshot?: boolean;
  snapshotNumber?: number;
  snapshotName?: string;
  snapshotDescription?: string;
}

export interface IRawStage {
  id: number;
  url: string;
  number: number;
  name: string;
  slug: string;
  description: string;
  rules: Array<IRawRule>;
  is_adhoc: boolean;
  is_deleted: boolean;
}

export interface IRawPathwayIndexEvent {
  id: number;
  url: string;
  rules: Array<IRawRule>;
  event_type_slug: string;
}

export interface IRawPathway {
  id: number;
  url: string;
  name: string;
  description: string;
  stages: IRawStage[];
  metadata: { [key: string]: any };
  source: string;
  index_events: Array<IRawPathwayIndexEvent>;
  is_active: boolean;
  is_deleted: boolean;
  language: string;
  num_app_user_pathways: number;
  owner_id: string;
  is_snapshot: boolean;
  is_shared_snapshot?: boolean;
  snapshot_number?: number;
  snapshot_name?: string;
  snapshot_description?: string;
}

export interface IRawPathwaySnapshot extends IRawPathway {
  is_snapshot: boolean;
  is_shared_snapshot: boolean;
  snapshot_number: number;
  snapshot_name: string;
  snapshot_description: string;
  shared_with?: string[];
}

export interface IRawSharedPathwaySnapshot extends IRawPathwaySnapshot {
  parent_organisation_slug: string;
  parent_name: string;
  parent_description: string;
  parent_index_event_types: { [key: string]: string };
}

export interface IPathwaySnapshot extends IPathway {
  isSnapshot: boolean;
  isSharedSnapshot: boolean;
  snapshotNumber: number;
  snapshotName: string;
  snapshotDescription: string;
  sharedWith?: string[];
}

export interface ISharedPathwaySnapshot extends IPathwaySnapshot {
  parentOrganisationSlug: string;
  parentName: string;
  parentDescription: string;
  parentIndexEventTypes: { [key: string]: string };
}

export interface IPathwaySnapshotData {
  name: string;
  description: string;
}
