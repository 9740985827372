import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { string, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Empty, Form, Button } from 'antd';
import IconButton from '@components/IconButton';
import DetailRow from '@components/DetailRow';
import { selectAppUser } from '@redux/appUsers/reducers';
import { Card } from '@cards/Card';
import { ColumnGroup, Column } from '@cards/Card';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { loadPatientDetails, createPatient, viewPatientInKemtai } from '../actions';
import { selectIsLoading, selectIsSubmitting, selectPatientDetailsForAppUserId } from '../reducers';
import useIntegrationConfiguration from '../../hooks/useIntegrationConfiguration';

const formItemLayout = {
  layout: 'vertical',
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
    md: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const AppUserKemtaiCard = ({ appUser, title, loading, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const integrationConfig = useIntegrationConfiguration('kemtai');
  const isLoading = useSelector(selectIsLoading);
  const isSubmitting = useSelector(selectIsSubmitting);
  const patientDetails = useSelector(selectPatientDetailsForAppUserId(appUser && appUser.id));
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    if (
      !hasLoaded &&
      !patientDetails &&
      integrationConfig &&
      integrationConfig.isEnabled &&
      appUser
    ) {
      console.log({
        hasLoaded,
        patientDetails,
        integrationConfig,
        appUser,
      });
      setHasLoaded(true);
      dispatch(loadPatientDetails(appUser.id));
    }
  }, [dispatch, hasLoaded, setHasLoaded, appUser, integrationConfig, patientDetails]);

  const handleEnableKemtai = useCallback(() => {
    dispatch(createPatient(appUser.id));
  }, [dispatch, appUser]);

  const handleViewPatientInKemtai = useCallback(() => {
    dispatch(viewPatientInKemtai(patientDetails));
  }, [dispatch, patientDetails]);

  if (!integrationConfig || !integrationConfig.isEnabled) {
    return null;
  }

  return (
    <Card
      className="app-user-details-card"
      noPadding
      loading={loading || isLoading}
      title={t(title)}
      extra={
        appUser && patientDetails
          ? [
              <IconButton
                icon={faExternalLink}
                onClick={handleViewPatientInKemtai}
                tooltip={t('kemtai:patient.viewInKemtaiButton')}
              />,
            ]
          : null
      }
      {...props}
    >
      {!appUser || isLoading ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <Form {...formItemLayout} className="detail-form">
          <ColumnGroup>
            <Column>
              {appUser && !patientDetails ? (
                <div className="detail-row">
                  <p>{t('kemtai:patient.kemtaiStatusNotEnabled')}</p>
                  <p>
                    <Button type="primary" loading={isSubmitting} onClick={handleEnableKemtai}>
                      {t('kemtai:patient.enableButton')}
                    </Button>
                  </p>
                </div>
              ) : null}
              {appUser && patientDetails ? (
                <>
                  <DetailRow
                    label={t('kemtai:patient.kemtaiStatus')}
                    value={
                      patientDetails.is_enabled
                        ? t('kemtai:patient.kemtaiStatusEnabled')
                        : t('kemtai:patient.kemtaiStatusDisabled')
                    }
                    // FIXME: add toggling of integration per App User
                  />
                  <DetailRow
                    label={t('kemtai:patient.kemtaiIDLabel')}
                    value={patientDetails.kemtai_id}
                  />
                </>
              ) : null}
            </Column>
          </ColumnGroup>
        </Form>
      )}
    </Card>
  );
};

AppUserKemtaiCard.propTypes = {
  title: string,
  appUser: object,
};

AppUserKemtaiCard.defaultProps = {
  title: 'kemtai:menu.kemtai',
};

const AppUserKemtaiByIdCard = ({ appUserId, ...props }) => {
  // const dispatch = useDispatch();

  //   useEffect(() => {
  //     dispatch(fetchAppUsers());
  //   }, [dispatch]);

  const [loading, appUser] = useSelector(selectAppUser(appUserId));

  return <AppUserKemtaiCard appUser={appUser} loading={loading} {...props} />;
};

export { AppUserKemtaiCard };

export default AppUserKemtaiByIdCard;
