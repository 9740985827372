import deburr from 'lodash.deburr';
import { mapRawRule } from '@pathways/redux/rules/utils';
import { GLOBAL, HOSPITAL } from '@constants';
import {
  IPathway,
  IRawPathway,
  IPathwayIndexEvent,
  IRawPathwayIndexEvent,
  IRawStage,
  IStage,
  IPathwaySnapshot,
  IRawPathwaySnapshot,
  IRawSharedPathwaySnapshot,
  ISharedPathwaySnapshot,
} from './types';

export const generateSlugName = (name: string) =>
  deburr(name.replace(/[^\w-]/g, ''))
    .toLowerCase()
    .replace(/\s/g, '-');

export const mapRawPathway = ({
  index_events: indexEvents,
  is_active: isActive,
  is_deleted: isDeleted,
  num_app_user_pathways: numAppUserPathways,
  metadata,
  stages,
  owner_id: ownerId,
  is_snapshot: isSnapshot,
  ...pathway
}: IRawPathway): IPathway => ({
  ...pathway,
  indexEvents: indexEvents
    ? indexEvents.map(indexEvent => mapRawPathwayIndexEvent(indexEvent))
    : [],
  isActive,
  isDeleted,
  metadata: metadata || { language: 'en' },
  source: metadata.owner || 'global',
  stages: stages.reduce((stages: IStage[], stage) => {
    const mappedStage = mapRawPathwayStage(stage);
    if (mappedStage) return [...stages, mappedStage];
    return stages;
  }, []),
  numAppUserPathways,
  ownerId,
  isSnapshot,
});

export const mapRawPathwayIndexEvent = ({
  event_type_slug: eventTypeSlug,
  rules,
  ...indexEvent
}: IRawPathwayIndexEvent): IPathwayIndexEvent => ({
  ...indexEvent,
  eventTypeSlug,
  rules: rules.map(rule => mapRawRule(rule)),
});

export const mapRawPathwayStage = ({
  is_adhoc: isAdhoc,
  is_deleted,
  rules,
  ...stage
}: IRawStage): IStage | undefined =>
  is_deleted
    ? undefined
    : {
        ...stage,
        isAdhoc,
        rules: rules.map(rule => mapRawRule(rule)),
      };

export const updatePathwayMetadata = (metadata: { [key: string]: any }, ownerId: string) => ({
  ...metadata,
  owner: ownerId === GLOBAL ? GLOBAL : HOSPITAL,
  hospitalId: ownerId === GLOBAL ? undefined : ownerId,
});

export const mapRawPathwaySnapshot = (rawSnapshot: IRawPathwaySnapshot): IPathwaySnapshot => {
  const {
    is_snapshot,
    is_shared_snapshot,
    snapshot_number,
    snapshot_name,
    snapshot_description,
    shared_with,
    ...rawPathway
  } = rawSnapshot;

  return {
    ...mapRawPathway({ ...rawPathway, is_snapshot }),
    isSnapshot: is_snapshot,
    isSharedSnapshot: is_shared_snapshot,
    snapshotNumber: snapshot_number,
    snapshotName: snapshot_name,
    snapshotDescription: snapshot_description,
    sharedWith: shared_with,
  };
};

export const mapRawSharedPathwaySnapshot = (
  rawSnapshot: IRawSharedPathwaySnapshot,
): ISharedPathwaySnapshot => {
  const {
    parent_organisation_slug,
    parent_name,
    parent_description,
    parent_index_event_types,
    ...rest
  } = rawSnapshot;

  return {
    ...mapRawPathwaySnapshot(rest),
    parentOrganisationSlug: parent_organisation_slug,
    parentName: parent_name,
    parentDescription: parent_description,
    parentIndexEventTypes: parent_index_event_types,
  };
};
